





























































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
@Component({
  components: {
    PacienteFichaMasOpciones: () => import("./paciente-ficha-mas-opciones.vue"),
  },
})
export default class PacienteFichaOpciones extends Vue {
  @PropSync("id_paciente") id_paciente_sync!: number;
  public dialog = false;
  public dialog_mas = false;
  public counterclickOp: any[] = [];
  created() {
    const result: any = this.allbuttons.filter(
      (item) => this.$route.name === item.routername
    );
    if (result[0].visible_paci_no_existe === false) {
      if (this.id_paciente_sync === undefined) {
        this.$router.push({
          name: RouterNames.pacienteslista,
        });
      }
    }
  }
  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }
  public get allbuttons() {
    return [
      {
        id: 1,
        titulo: "Ficha",
        descripcion: "Datos del paciente",
        icono: "fad fa-user-alt ",
        visible_paci_no_existe: true,
        routername: RouterNames.pacientesedit,
      },
      {
        id: 2,
        titulo: "Dietas",
        descripcion: "Historial de dietas asignadas al paciente",
        icono: "fad fa-utensils-alt",
        visible_paci_no_existe: false,
        routername: RouterNames.hist_dietas,
      },
      {
        id: 3,
        titulo: "Patologías",
        descripcion: "patologías del paciente",
        icono: "fas fa-user-md",
        visible_paci_no_existe: false,
        routername: RouterNames.patologias_paciente,
      },
      {
        id: 4,
        titulo: "Consultas",
        descripcion: "consultas del paciente",
        icono: "fad fa-apple-alt",
        visible_paci_no_existe: false,
        routername: RouterNames.consultas_paciente,
      },
      {
        id: 5,
        titulo: "Seguimiento",
        descripcion: "Graficos de seguimiento",
        icono: "fad fa-tachometer-slow",
        visible_paci_no_existe: false,
        routername: RouterNames.seguimiento_paciente,
      },
      {
        id: 6,
        titulo: "Mas",
        descripcion: "Ver más opciones",
        icono: "fad fa-filter",
        visible_paci_no_existe: false,
        routername: "",
      },
    ];
  }
  public get botones() {
    let botones: any[] = this.allbuttons;
    let botones_visibles: any[] = [];
    for (let i = 0; i < botones.length; i++) {
      const element = botones[i];
      if (this.id_paciente_sync === undefined) {
        if (element.visible_paci_no_existe) {
          botones_visibles.push(element);
        }
      } else {
        botones_visibles.push(element);
      }
    }
    return botones_visibles;
  }
  public showPopup(): boolean {
    const result = this.counterclickOp.filter(
      (item) => this.$route.name === item.routername
    );

    return result.length >= 3;
  }
  public clickbutton(item: any) {
    if (this.$route.name === item.routername) {
      this.counterclickOp.push(item);
      this.dialog = this.showPopup();
      return;
    }
    switch (item.id) {
      case 1:
        this.$router.push({
          name: RouterNames.pacientesedit,
          query: {
            id: UtilsEncript.Encriptar(this.id_paciente_sync.toString()),
          },
        });
        break;
      case 2:
        this.$router.push({
          name: RouterNames.hist_dietas,
          query: {
            id: UtilsEncript.Encriptar(this.id_paciente_sync.toString()),
          },
        });
        break;
      case 3:
        this.$router.push({
          name: RouterNames.patologias_paciente,
          query: {
            id: UtilsEncript.Encriptar(this.id_paciente_sync.toString()),
          },
        });
        break;
      case 4:
        this.$router.push({
          name: RouterNames.consultas_paciente,
          query: {
            id: UtilsEncript.Encriptar(this.id_paciente_sync.toString()),
          },
        });
        break;

      case 5:
        this.$router.push({
          name: RouterNames.seguimiento_paciente,
          query: {
              id: UtilsEncript.Encriptar(this.id_paciente_sync.toString()),
          },
        });
        break;
      case 6:
        this.dialog_mas = true;
        break;
    }
  }
}
